.faq {

    p span, .label span {
        color: $brand-name-dark;
        font-weight: bold;
    }

    .icon-question-mark {
        background: url('/images/question_mark.svg') no-repeat left top;
        width: 115px;
        height: 190px;
        position: absolute;
        left: 12%;
    }

    .container {

        h2 {
            font-size: 20px;
            font-weight: 700;
            line-height: 29px;
        }

        h3 {
            font-size: 18px;
            font-weight: 700;
        }
    }

    .faq-downloads {
        background: $brand-sec;
        padding: 40px;

        ul {
            li {
                list-style: none;

                a {
                    color: $brand-app;
                    display: inline-flex;
                    align-items: center;

                    span.icon {
                        margin-right: 20px;

                        &:before {
                            background: url("/images/deprexis_download_icon.svg") $brand-app no-repeat center;
                            border-radius: 50%;
                            content: " ";
                            display: inline-flex;
                            height: 60px;
                            width: 60px;
    
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .faq {
        .container {
            h2 {
                font-size: 40px;
                font-weight: 700;
                line-height: 29px;
            }
    
            h3 {
                font-size: 40px;
                font-weight: 700;
            }
        }
    }
}