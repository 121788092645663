.request {
    @include padding-bottom;
    @include text-style;

    h3 {
        @include margin-bottom-half;
    }

    .request-cards .row {
        @include span-light;
        margin-top: 30px;
        background-color: $card-left-bg-page1;
        color: $brand-heading-dark;

        .btn-img-bg {
            background-color: $card-left-span-page1;
            width: 140px;
            height: 140px;
        }

        img { 
            width: 90px;
            height: 90px;
        }

        h4 {
            margin-bottom: 20px;
            font-size: 22px;
        }

        a {
            font-weight: 700;
            color: $brand-heading-dark;
            white-space: nowrap;
        }

        span {
            color: $brand-app;
        }

        .bob-style {
            color: #fff;
            background-color: $brand-app;
            margin-top: 20px;
            width: 100%;
        }
    }
}