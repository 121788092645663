.block {
    margin: 10px 0 60px 0;

    .row {
        padding: 40px;

        h3 {
            font-size: 2rem;
        }

        .bob-style {
            background: $brand-app;
            color: #fff;
            padding: 8px 40px;
        }
    }

    p span {
        color: $brand-name-dark;
        font-weight: bold;
    }

    .bg {
        background: $brand-sec;

    }

    &.dark {
        .bg {
            background: $brand-custom-sec;
            color: #fff;
        }

        .row {
            padding: 50px 40px;
            .col {
                align-self: center;
            }
        }
    }
    
}