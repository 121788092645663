.webinarv2-section {

    .webinar {
        .row {
            justify-content: center;
        }

        .webinar-content {
            background: $brand-sec;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 15px;
            padding: 30px;
            width: 100%;

            .date {
                .dateIcon {
                    width: 40px;
                }

                .dateText {
                    font-size: 18px;
                    color: $brand-app;
                }
            }

            .mini {
                font-weight: bold;
            }

            span.speaker {
                color: $brand-app;
                padding-bottom: 30px;
            }

            .btn {
                align-self: flex-end;
                background: $brand-app;
                color: #fff;
                width: 60%;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .webinarv2-section {

        .webinar {
    
            .webinar-content {
                width: 45%;
            }
        }
    }
}
