.section-teleclinic {
    background-color: #c3a3df;

    a {
        background-color: #362a62;
        color: #fff;
    }

    p {
        color: #231b3e;
    }
}