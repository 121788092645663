.steps {
    h2, h3 {
        @include span-dark;
    }

    .container {
        background: $steps-bg;
        padding: 40px !important;
    }

    p {
        @include span-dark;
    }

    .bob-style {
        background: $brand-app;
        color: #fff;
        padding: 8px 40px;
    }

    .steps-content {
        margin-top: 0;
    }

    .back-btn:focus {
        background-color: transparent !important;
    }

    .form-check {
        .form-check-input {
            position: relative;
            width: 40px;
            height: 40px;
            margin: 0;
        }
    }

    .form-control {
        border-radius: 20px;
        background-color: #F9F9F9;
    }

    input::placeholder, select option.is-invalid {
        color: #AFAFAF;
        font-style: normal;
    }

    select {
        font-style: normal;
        padding-right: 10px;
    }

    .form-group-file {
        input {
            height: 150px;
            opacity: 0;
            position: absolute;
            margin-top: -110px;
        }

        .form-control.is-invalid {
            border-color: #dc3545;
        }

        .form-control {
            position: relative;
            width: 100%;
            height: 150px;
            border-radius: 20px;
            background-color: #F9F9F9;
            border: 1px solid #ced4da;

            .error-font {
                color: red;
            }
        }
    }

    .progress {
        height: 0.5rem;
        border-radius: 0;
        background-color: #cca7eb;

        .progress-bar {
            background-color: #7d6cbc;
        }
    }

    .cont-usps {
        background-color: #cda8ed;
    }
}

@include media-breakpoint-up(md) {
    .steps {
        .steps-content {
            .container {
                h3,
                p {
                    font-size: 24px;
                    line-height: 1.5;
                }

                p {
                    a {
                        font-size: 24px;
                    }
                }

                .animate-icon {
                    padding: 30px;
                }
            }
        }

        .info-content {
            min-height: 240px;
        }
    }
}